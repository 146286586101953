 
/* navbar  */
.mainnavdiv {
	overflow-x: hidden;
	position: sticky;
	top: 0;
	padding: 0;
	margin: 0;
	color: grey;
	
  }
  
  .navbar {
	color: #333;
	background-color: brown;

  }
  
  .navbar-nav {
	display: flex;
	justify-content: center;
	margin: auto;
	flex-direction: row;
	padding: 0;
  }
  
  .nav-item {
	margin-right: 10px;
  }
  
  .nav-link {
	color: black;
	padding: 0px;
	font-size: 20px;
	color:white;

  }
  
  .nav-link:hover{
	color: rgb(235, 147, 147);
  }
  
  
  hr {
	color:white;
	padding: 0;
	margin: 0;
  }
  .dot{
	  margin-right: 10px;
	  color: white;
	  padding: 0px;
	  font-size: 20px;
  }
/* navbar end  */

  @font-face {
	font-family: myFirstFont;
	src: url(Hastage.ttf);
  }
  .div1{
	display:none;
  }

  @media only screen and (max-width: 768px) {
	/* .div0{
		margin-top: 2px;
		margin-bottom: 4px;
	} */
	.div1{
		display: block;
		margin-top: 2px;
		margin-bottom: 4px;
	}
	.div0{
		display:none;
	}
	.dot{
		margin-right: 10px;
		color: white;
		padding: 0px;
		font-size: 15px;
	}
	.nav-link {
		color: black;
		padding: 0px;
		font-size: 15px;
		color:white;
	
	  }
  }
  /* nav logo end  */

/* card start  */
.cardcontainer{
	display: flex;
	flex-wrap: no-wrap;
    align-items:center;
    justify-content: space-around;
}

@media only screen and (max-width: 768px) {
	.cardcontainer{
		background-image: url('../public/home2.png');
		text-align: center;
		background-size: cover;
	}

	.image{
		display: none;
	}
	.text{
		height:75vh;
	}
}

/* about us  */
/* .aboutusimg{
	width:60vh;
} */

/* about us end  */


/* Contact us  */


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.form-main {
  background: linear-gradient(to bottom, #00000024, #00000024),
    url('../public/home.png')
      no-repeat center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .main-wrapper {
    border-radius: 10px;
    padding: 45px;
    width: 40%;
    box-shadow: 0 0 5px 5px #00000020;
    backdrop-filter: blur(5px);
    background-color: #ffffff85;
    @media screen and (max-width: 991px) {
      width: 70%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
    }
    .form-head {
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      margin: 0px 0 25px;
    }
    .form-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      .form-card {
        position: relative;
        width: 100%;

        .form-input {
          padding: 20px 25px 15px;
          width: 100%;
          border: 1px solid black;
          border-radius: 5px;
          background: transparent;
          outline: none;
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
              box-sizing: border-box;

          &:valid,
          &:focus {
            border: 1px solid gray;
          }

          &:valid ~ .form-label,
          &:focus ~ .form-label {
            color: gray;
            top: 30%;
            transform: translateY(-70%);
            font-size: 13px;
            line-height: 23px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 9999s ease-in-out 0s;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .form-label {
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          transition: 0.3s;
          margin: 0;
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
        .form-textarea {
          padding: 20px 25px 15px;
          width: 100%;
          border: 1px solid black;
          border-radius: 5px;
          background: transparent;
          outline: none;
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          resize: none;
              box-sizing: border-box;

          &:valid,
          &:focus {
            border: 1px solid gray;
          }

          &:valid ~ .form-textarea-label,
          &:focus ~ .form-textarea-label {
            color: gray;
            top: 18%;
            transform: translateY(-82%);
            font-size: 13px;
            line-height: 23px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 9999s ease-in-out 0s;
          }
        }
        .form-textarea-label {
          position: absolute;
          left: 25px;
          top: 30%;
          transform: translateY(-70%);
          pointer-events: none;
          transition: 0.3s;
          margin: 0;
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
      }
    }
  }
}
.btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
padding: 16px 0 0;
  }
.btn-wrap button {
  padding: 0 32px;
  font-size: 18px;
  line-height: 48px;
  border: 1px solid transparent;
  font-weight: 600;
  border-radius: 6px;
  transition: all 0.5s ease;
  cursor: pointer; box-shadow: 0 0 5px 5px #00000020;
}
.btn-wrap button:hover {
  border: 1px solid #000;
  background: transparent;
  
}

/* contact us button  */
.hovergreen:hover{
	background-color:#25D366;
  color:white;
  border:white;
}

.lightred{
  background-color:rgb(227,179,179);
}

.brown{
  color:brown;
}

